<template>
  <div>
    <empty-list
      v-if="!items"
      text="Estamos com problemas para carregar os certificados tente novamente mais tarde"
    />
    <div class="d-flex align-items-center justify-content-between mb-3" v-else>
      <h3>{{ calcAllInputedCertificates() }}/{{ items.length }}</h3>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-end mr-md-4">
          <div class="d-flex align-items-center mr-2">
            <b-badge
              variant="success"
              size="sm"
              class="mr-2 px-1 pointer"
              @click="handleFilter('has')"
            />
            <span> Anexados</span>
          </div>
          <div class="d-flex align-items-center">
            <b-badge
              variant="danger"
              size="sm"
              class="pointer px-1 mr-2"
              @click="handleFilter('empty')"
            ></b-badge>
            <span>Não Anexados</span>
          </div>
        </div>
        <!-- <b-button class="btn-add with-content" @click="handleImportAutomatic()">
          Importar automaticamente
        </b-button> -->
      </div>
    </div>
    <cards-list grid="1" class="mr-0">
      <b-card
        v-for="(item, index) in items"
        :key="index"
        class="
          mb-3
          px-3
          py-md-1 py-3
          d-md-flex
          flex-md-row
          w-100
          justify-content-between
        "
        no-body
      >
        <strong class="d-flex align-items-center">
          <b-badge
            class="mr-2 p-1"
            :class="item.certificate ? 'badge-success' : 'badge-danger'"
          >
            <b-icon :icon="item.certificate ? 'check-circle' : 'x-circle'" />
          </b-badge>
          <div>
            <span>{{ item.title }} ( {{ item.state }})</span>
            <small class="d-block">
              Certidão atualizada em:
              {{ item.updated_certificate | dateFull }}
            </small>
          </div>
        </strong>

        <div
          class="
            d-flex
            align-items-center
            justify-content-end
            col-md-5
            gap-4 
          "
        >
          <b-button            
            size="sm"
            class="d-flex align-items-center justify-content-end w-100"
            variant="light"
            :href="item.url"
            target="_blank"
            v-b-popover.hover.top="
              `Visitar o sistema do ${item.title.split(' – ')[1]} (${
                item.state
              })`
            "
          >
            <b-icon icon="globe" class="mr-2" />
            <span class="text-nowrap">Visitar o sistema</span>
          </b-button>

          <b-button
            class="px-3 w-100"
            size="sm"
            variant="primary"
            @click="handleOpenCertificate(item)"
            target="_blank"
            v-if="item.certificate"
          >
            <b-icon icon="patch-check" class="mr-2" />
            <span>Certificado anexado</span>
          </b-button>
          <b-button
            v-else
            size="sm"
            variant="info"
            @click="handleAddCertificate(item, index)"
            target="_blank"
            class="w-100"
          >
            <b-icon icon="paperclip" /> <span>Anexar certificado</span>
          </b-button>
        </div>
      </b-card>
    </cards-list>
    <b-modal
      id="add-certificate"
      size="md"
      hide-footer
      centered
      :title="
        editCertificate
          ? `Editar certidão do ${selectedCertificate.title.split(' – ')[1]}`
          : 'Adicionar certidão'
      "
    >
      <b-card
        no-body
        class="p-2"
        v-if="selectedCertificate && selectedCertificate.certificate"
      >
        <span class="d-block mb-2">
          Certidão anexada em
          {{ selectedCertificate.updated_certificate | dateFull }}
        </span>
        <a
          class="text-left d-flex align-items-center line-height-1"
          v-bind:href="selectedCertificate.certificate.file"
          target="_blank"
          download
        >
          <b-icon icon="paperclip" class="mr-2 m-0" />
          <span> {{ selectedCertificate.certificate.name }}</span>
        </a>
      </b-card>

      <file-upload @upload="handleUploadCertificate" />
    </b-modal>
    <b-modal
      id="automatic-certificate"
      size="md"
      :hide-footer="!erros_imports.length"
      hide-header
      body-class="py-4"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <div
        class="d-flex align-items-center flex-column"
        v-if="!erros_imports.length"
      >
        <blockquote>Fazendo a importação automática das certidões</blockquote>
        <b-spinner
          style="width: 3rem; height: 3rem"
          variant="primary"
          class="my-5"
        />

        <b-progress max="100" height="9px" class="w-100">
          <b-progress-bar :value="automatic_attemp" />
          <progress-line class="loading" />
        </b-progress>
      </div>
      <div v-else>
        <strong class="d-block">
          Não conseguimos importar automaticamente as certidões dos seguintes
          tribunais
        </strong>
        <div class="my-2">
          <p v-for="error in erros_imports" :key="error">
            <b-icon icon="x-circle" variant="danger" /> {{ error }}
          </p>
        </div>
      </div>
      <template #modal-footer="{ close }">
        <div class="w-100 d-flex justify-content-end">
          <b-button variant="primary" @click="close()"> Fechar </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import FileUpload from "@/components/FileUpload";
import ProgressLine from "@/components/Progress";
import { mapActions } from "vuex";

export default {
  mixins: [listPaginated],

  components: {
    FileUpload,
    ProgressLine,
  },
  data() {
    return {
      editCertificate: false,
      importing: false,
      per_page: 24,
      selectedCertificate: {},
      uploadedFiles: [],
      erros_imports: [],
      action_name: "labor_lawsuits",
      params: {},
      automatic_attemp: 0,
      filter: "",
      selectedUser: {
        id: null,
      },
      provider: null,
      status: 1,
    };
  },
  methods: {
    ...mapActions(["edit_labor_lawsuits", "import_certificate"]),
    calcAllInputedCertificates() {
      return this.items?.filter((item) => item.certificate).length;
    },
    handleUploadCertificate(file) {
      const id = file[0].id;
      this.selectedCertificate = {
        ...this.selectedCertificate,
        certificate: id,
      };
      this.handleEditCertificate(file.id);
    },
    handleFilter(filter) {
      this.getList();
      this.filter = filter;
      if (filter === "has") {
        this.items = this.items.filter((item) => item.certificate);
      }
      if (filter === "empty") {
        this.items = this.items.filter((item) => !item.certificate);
      }
    },
    handleEditCertificate() {
      this.edit_labor_lawsuits({
        ...this.selectedCertificate,
        updated_certificate: new Date().toISOString(),
      }).then(() => this.getList());
      this.$forceUpdate();
      this.$bvModal.hide("add-certificate");
    },
    handleOpenCertificate(certificate) {
      this.editCertificate = true;
      this.selectedCertificate = certificate;
      this.$bvModal.show("add-certificate");
    },
    handleAddCertificate(item) {
      this.selectedCertificate = item;
      this.$bvModal.show("add-certificate");
    },
    handleImportAutomatic() {
      this.$bvModal.show("automatic-certificate");
      this.importing = true;
      this.erros_imports = [];
      this.items.forEach(async (el, index) => {
        await this.import_certificate(el.id).then(() => {
          this.automatic_attemp = index * 10;
          if (this.items.length - 1 === index) {
            this.importing = false;
            this.getList();
            this.$bvModal.hide("automatic-certificate");
            this.automatic_attemp = 0;
            this.erros_imports = this.items.flatMap(({ certificate, title }) =>
              !certificate ? [title] : []
            );
          }
        });
      });
    },
    handleImportManual() {
      this.$bvModal.show("add-certificate");
    },
  },
};
</script>
