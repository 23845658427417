var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.items)?_c('empty-list',{attrs:{"text":"Estamos com problemas para carregar os certificados tente novamente mais tarde"}}):_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-3"},[_c('h3',[_vm._v(_vm._s(_vm.calcAllInputedCertificates())+"/"+_vm._s(_vm.items.length))]),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-end mr-md-4"},[_c('div',{staticClass:"d-flex align-items-center mr-2"},[_c('b-badge',{staticClass:"mr-2 px-1 pointer",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.handleFilter('has')}}}),_c('span',[_vm._v(" Anexados")])],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-badge',{staticClass:"pointer px-1 mr-2",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.handleFilter('empty')}}}),_c('span',[_vm._v("Não Anexados")])],1)])])]),_c('cards-list',{staticClass:"mr-0",attrs:{"grid":"1"}},_vm._l((_vm.items),function(item,index){return _c('b-card',{key:index,staticClass:"mb-3 px-3 py-md-1 py-3 d-md-flex flex-md-row w-100 justify-content-between",attrs:{"no-body":""}},[_c('strong',{staticClass:"d-flex align-items-center"},[_c('b-badge',{staticClass:"mr-2 p-1",class:item.certificate ? 'badge-success' : 'badge-danger'},[_c('b-icon',{attrs:{"icon":item.certificate ? 'check-circle' : 'x-circle'}})],1),_c('div',[_c('span',[_vm._v(_vm._s(item.title)+" ( "+_vm._s(item.state)+")")]),_c('small',{staticClass:"d-block"},[_vm._v(" Certidão atualizada em: "+_vm._s(_vm._f("dateFull")(item.updated_certificate))+" ")])])],1),_c('div',{staticClass:"d-flex align-items-center justify-content-end col-md-5 gap-4"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
            `Visitar o sistema do ${item.title.split(' – ')[1]} (${
              item.state
            })`
          ),expression:"\n            `Visitar o sistema do ${item.title.split(' – ')[1]} (${\n              item.state\n            })`\n          ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center justify-content-end w-100",attrs:{"size":"sm","variant":"light","href":item.url,"target":"_blank"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"globe"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("Visitar o sistema")])],1),(item.certificate)?_c('b-button',{staticClass:"px-3 w-100",attrs:{"size":"sm","variant":"primary","target":"_blank"},on:{"click":function($event){return _vm.handleOpenCertificate(item)}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"patch-check"}}),_c('span',[_vm._v("Certificado anexado")])],1):_c('b-button',{staticClass:"w-100",attrs:{"size":"sm","variant":"info","target":"_blank"},on:{"click":function($event){return _vm.handleAddCertificate(item, index)}}},[_c('b-icon',{attrs:{"icon":"paperclip"}}),_vm._v(" "),_c('span',[_vm._v("Anexar certificado")])],1)],1)])}),1),_c('b-modal',{attrs:{"id":"add-certificate","size":"md","hide-footer":"","centered":"","title":_vm.editCertificate
        ? `Editar certidão do ${_vm.selectedCertificate.title.split(' – ')[1]}`
        : 'Adicionar certidão'}},[(_vm.selectedCertificate && _vm.selectedCertificate.certificate)?_c('b-card',{staticClass:"p-2",attrs:{"no-body":""}},[_c('span',{staticClass:"d-block mb-2"},[_vm._v(" Certidão anexada em "+_vm._s(_vm._f("dateFull")(_vm.selectedCertificate.updated_certificate))+" ")]),_c('a',{staticClass:"text-left d-flex align-items-center line-height-1",attrs:{"href":_vm.selectedCertificate.certificate.file,"target":"_blank","download":""}},[_c('b-icon',{staticClass:"mr-2 m-0",attrs:{"icon":"paperclip"}}),_c('span',[_vm._v(" "+_vm._s(_vm.selectedCertificate.certificate.name))])],1)]):_vm._e(),_c('file-upload',{on:{"upload":_vm.handleUploadCertificate}})],1),_c('b-modal',{attrs:{"id":"automatic-certificate","size":"md","hide-footer":!_vm.erros_imports.length,"hide-header":"","body-class":"py-4","centered":"","no-close-on-backdrop":"","no-close-on-esc":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ close }){return [_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return close()}}},[_vm._v(" Fechar ")])],1)]}}])},[(!_vm.erros_imports.length)?_c('div',{staticClass:"d-flex align-items-center flex-column"},[_c('blockquote',[_vm._v("Fazendo a importação automática das certidões")]),_c('b-spinner',{staticClass:"my-5",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary"}}),_c('b-progress',{staticClass:"w-100",attrs:{"max":"100","height":"9px"}},[_c('b-progress-bar',{attrs:{"value":_vm.automatic_attemp}}),_c('progress-line',{staticClass:"loading"})],1)],1):_c('div',[_c('strong',{staticClass:"d-block"},[_vm._v(" Não conseguimos importar automaticamente as certidões dos seguintes tribunais ")]),_c('div',{staticClass:"my-2"},_vm._l((_vm.erros_imports),function(error){return _c('p',{key:error},[_c('b-icon',{attrs:{"icon":"x-circle","variant":"danger"}}),_vm._v(" "+_vm._s(error)+" ")],1)}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }